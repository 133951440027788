import * as React from "react";
import classnames from "classnames";

type Props = React.HTMLProps<HTMLInputElement> & {
  border?: "none" | "bottom";
  padding?: "left" | "both";
  transform?: "capitalize";
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "bottom":
      return "border-b border-grey-2";
    case "none":
      return "border-none";
    default:
      return "border border-getgrey-3";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    default:
      return "w-full";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "both":
      return "py-2 px-8";
    case "left":
      return "py-2 pl-2 pr-10";
    default:
      return "p-2";
  }
};

let getTransform = (transform: Props["transform"]) => {
  switch (transform) {
    case "capitalize":
      return "capitalize";
    default:
      return "normal-case";
  }
};

export const Inputs = ({ border, width, padding, transform, ...props }: Props) => {
  return (<>
    <input
      className={classnames(
        "focus:outline-none text-xs bg-transparent",
        getBorder(border),
        getWidth(width),
        getPadding(padding),
        getTransform(transform)
      )}
      {...props}
    />
    <span className="bar"></span></>
  );
};
