/* eslint-disable jsx-a11y/alt-text */
import { Span, SubContainer } from "@common"; 
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./Transaction.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  transaction?: any;
};

export const Transactions = ({ transaction }: Props) => {
  const canceled = transaction?.canceled;
  const delivered = transaction?.delivered;

  const data = {
    datasets: [
      {
        label: "Total",
        data: [delivered, canceled],
        backgroundColor: ["#B1CB60", "#FF7F14"],
        borderColor: ["#fff", "#fff"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <>
      <SubContainer padding="px-2">
        <div className="transaction">
          <div className="label">
            <p>
              <Span color="lightgreen" size="2xl" weight="bold">
                {delivered}
              </Span>
            </p>
            <p>
              <Span weight="semi">Completed</Span>
            </p>
          </div>

          <div className="h-96" >
          <div className="transaction-doughnut">
            <Doughnut data={data} options={options} />
          </div>
          </div>

          <div className="label">
            <p>
              <Span color="orange" size="2xl" weight="bold">
                {canceled}
              </Span>
            </p>
            <p>
              <Span weight="semi">Cancelled</Span>
            </p>
          </div>
        </div>
      </SubContainer>
    </>
  );
};
