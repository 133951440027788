import { getResto } from "@api";
import { InputContainerNew } from "@components";
import { Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

type Props = {
  name?: string;
};

export const SelectResto = ({ name }: Props) => {
    
  const [resto, setResto] = useState([]) as any;
  
  
  const fetchRestaurant = async () => {
    try {
      const resto = await getResto();
      setResto(resto); 
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    fetchRestaurant(); 
  }, []);

  const renderSelectResto = useCallback(() => {
    return (
      <InputContainerNew icon={false} label="Restaurant">
        <Form.Item name="listChosenRestaurants" className="w-full">
          <Select
            className="my-2"
            showSearch
            filterOption={(input, option: any) =>
              (option.children as any)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            placeholder="Select a Restaurant"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            mode="multiple"
          >
            {resto.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </InputContainerNew>
    );
  }, [resto]);
  
  return <>
  
  {renderSelectResto()}
  </>;
};
