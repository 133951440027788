/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { postAccessValidation, postSetPassword } from "@api";
import { Span, CardContainer, Container, Button } from "@common";
import {
  PasswordContainer,
  RegistrationLinkExpired,
  Loader,
  ErrorMessage,
} from "@components";
import { BRAND_IMAGES } from "@constant";
import { Dispatch } from "@store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const SetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  var t = window?.location?.search?.match(/(\?|&)accessToken=([^&]*)/) ?? [];
  var accessToken = t[2];

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const validation = !password || !passwordConfirm;

  const [error, setError] = useState("");

  const [accessValidation, setAccessValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAccess = async () => {
    setIsLoading(true);
    try {
      const res = await postAccessValidation(accessToken);
      if (!res) {
        setAccessValidation(false);
      }
    } catch {
      setAccessValidation(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccess();
  }, []);

  let handelSetPassword = async () => {
    try {
      const result = await postSetPassword(
        accessToken,
        password,
        passwordConfirm
      );

      if (result) {
        dispatch.UI.setAlert({
          message: "Successfully set new password!",
          type: "Success",
        });
        history.push("/");
      }
    } catch (err: any) {
      const errMsg = err?.response?.data.message ?? "Invalid password!";
      setError(errMsg);
    }
  };

  return (
    <>
      {!isLoading ? (
        !accessValidation ? (
          <CardContainer>
            <img
              src={BRAND_IMAGES["BRAND_LOGO"]}
              alt="AEG Logo"
              className="brand-logo"
            />
            <Container margin="my-8" align="center">
              <Span weight="semi" size="base">
                Set up your AllEasy Go Merchant Portal account
              </Span>
            </Container>

            {error && (
              <>
                <ErrorMessage type="error" message={error} />
              </>
            )}

            <PasswordContainer
              placeholder="Password"
              id="password"
              minLength={8}
              maxLength={80}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
            />

            <PasswordContainer
              placeholder="Confirm Password"
              id="passwordConfirm"
              minLength={8}
              maxLength={80}
              onChange={(e: any) => {
                setPasswordConfirm(e.target.value);
              }}
            />

            <Button
              margin="top"
              onClick={handelSetPassword}
              disabled={validation}
            >
              Save Password
            </Button>
          </CardContainer>
        ) : (
          <RegistrationLinkExpired />
        )
      ) : (
        <div className="flex h-screen justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};
