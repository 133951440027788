import { BiSortDown, BiSortUp } from "@icons";
import { useTable, useSortBy } from "react-table";
import "./Table.scss";

type Props = {
  data: any;
  columns: any;
};

export const Table = ({ columns, data }: Props) => {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {/* <div className="flex whitespace-nowrap font-normal table-header"> */}
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BiSortDown className="my-auto text-lg mx-2" />
                      ) : (
                        <BiSortUp className="my-auto text-lg mx-2" />
                      )
                    ) : (
                      ""
                    )}
                  {/* </div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
