import { FaPen } from '@icons';
import { Container } from "@common"
import { Inputs } from "@common" 
import { Span } from '@common';

type Props = { 
    type: string, 
    name: string,
    value?: string,
    label: string,
    placeholder?: string,
    icon?: boolean
    required?: boolean
}
export const InputContainer = ({ type, name, label, placeholder, icon, value, required}: Props) => { 
    return ( 
        <Container padding='p-4'>
            <Span color='grey'>{label}</Span> 
            <Container className="index" flex='wrap' margin='none'>    
            <Inputs 
                padding="left"
                border="bottom" 
                name={name}  
                value={value} 
                type={type} 
                placeholder={placeholder}
                required={required}
            />  

            {icon &&
            <span className='pencil'>
                <FaPen />
            </span>}
            </Container>
        </Container>
    );
}   