import {Span } from "@common";

type Props = {   
    children: React.ReactNode,
    icon?: any, 
}
export const IconContainer = ({ icon, children }: Props) => { 
    return ( 
        <div className="my-auto relative flex p-2 bg-white rounded-md">
            <Span position="absolute" size="xl"> 
            {icon}
            </Span>
            {children}
        </div>
    );
}   