import * as React from 'react';
import classnames from 'classnames'

type Props =  {
  children?: React.ReactNode, 
  color?: 'blue' | 'white' | 'orange' | 'grey' | 'red' | 'green' | 'lightgreen' | 'maroon',
  weight?: 'default' | 'semi' | 'bold',
  size?: 'sm' | 'base' | 'lg' | 'xl' | '2xl',
  margin?: 'auto' | 'my-auto' | 'mx-2' | 'mx-1' | 'my-2' | 'mr-2' | 'mt-2' | 'mt-1',
  padding?: 'px-8',
  position?: 'relative' | 'absolute',
  decor?: 'underline' | "default"
}

let getColor = (color: Props['color']) => {
    switch (color) { 
    case 'lightgreen':
      return 'text-green-1';
    case 'green':
      return 'text-green-2';
    case 'maroon':
      return 'text-red-2';
    case 'red':
      return 'text-red-1';
    case 'grey': 
      return 'text-grey-1';
    case 'orange': 
      return 'text-primary-main';
    case 'white': 
      return 'text-white';
    case 'blue':
      return 'text-blue-1';
    default:
      return 'text-black';
  }
}

let getFontWeight = (weight: Props['weight']) => {
  switch (weight) {   
    case 'bold':
      return 'font-bold';
    case 'semi':
      return 'font-semibold';
    default:
      return 'font-normal';
  }
} 

let getFontSize = (size: Props['size']) => {
  switch (size) {  
    case '2xl':
      return 'text-2xl'; //40px
    case 'xl':
      return 'text-xl';  
    case 'lg':
      return 'text-lg';   
    case 'base':
      return 'text-base';  
    case 'sm':
      return 'text-sm';  
    default:
      return 'text-xs';
  }
}

let getPadding = (padding: Props["padding"]) => {
  switch (padding) { 
    case "px-8":
      return "px-8"; 
    default:
      return "p-0";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {  
    case "mt-1":
      return "mt-1";
    case "mt-2":
      return "mt-2";
    case "my-2":
      return "my-2";
    case "mr-2":
      return "mr-2"; 
    case "mx-1":
      return "mx-1"; 
    case "mx-2":
      return "mx-2";
    case "my-auto":
      return "my-auto mx-2"; 
    case "auto":
      return "m-auto"; 
    default:
      return "m-0";
  }
};

let getPosition = (position: Props['position']) => {
  switch (position) { 
    case 'absolute':
      return 'absolute';
    case 'relative':
      return 'relative';
    default:
      return '';
  }
}


let getTextDecoration = (decor: Props['decor']) => {
  switch (decor) {   
    case 'underline':
      return 'underline';  
    default:
      return 'no-underline';
  }
}

export const Span = ({children, color, weight, size, margin, padding, position, decor}: Props) => { 
    return <span className={classnames(getColor(color), getFontWeight(weight), getFontSize(size),
            getMargin(margin), getPadding(padding), getPosition(position), getTextDecoration(decor)
            )}>{children}
            </span>
}