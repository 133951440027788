import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLButtonElement> & {
  children: React.ReactNode;
  type?: 'submit';
  bg?: 'white' | 'orange';
  color?: 'grey' | 'black';
  margin?: 'auto' | 'top' | 'm-4' | 'none';
  padding?: 'none' | 'px-6' | 'p-2';
  radius?: 'none'; 
  border?: 'grey'
};

let getRadius = (radius: Props['radius']) => {
    switch (radius) {  
    case 'none':
        return 'rounded-none'  
    default:
        return 'rounded-md';
    }
}

let getPadding = (padding: Props['padding']) => {
    switch (padding) { 
      case 'p-2':
        return 'p-2';
      case 'px-6':
        return 'py-2 px-6';
      case 'none':
        return 'p-none'; 
      default:
        return 'py-2 px-4';
    }
};

let getMargin = (margin: Props['margin']) => {
    switch (margin) {
      case 'none':
        return 'm-0';
      case 'm-4':
        return 'm-4';
      case 'top':
        return 'mt-8 mb-4';
      case 'auto':
        return 'm-auto';
      default:
        return 'my-4';
    }
};

let getColor = (color: Props['color']) => {
    switch (color) { 
      case 'black':
        return 'text-black';
      case 'grey':
        return 'text-grey-1';
      default:
        return 'text-white';
    }
};

let getBg = (bg: Props['bg']) => {
  switch (bg) {
    case 'orange':
      return 'bg-primary-main';
    case 'white':
      return 'bg-white';
    default:
      return 'bg-grey-1 hover:bg-primary-main';
  }
};

let getWidth = (width: Props['width']) => {
    switch (width) { 
      case 'none':
        return 'w-none';
      default:
        return 'w-full';
    }
}; 

let getBorder = (border: Props['border']) => {
  switch (border) { 
    case 'grey':
      return 'border border-grey-4';
    default:
      return 'border-none';
  }
}; 

export const Button = ({ children, type, bg, width, color, margin, padding, radius, border, ...props }: Props) => {
  return (
    <button
      className={classnames(
        'focus:outline-none whitespace-nowrap tracking-wider font-semibold text-sm',
        getBg(bg), getWidth(width), getColor(color), getMargin(margin), getPadding(padding), 
        getRadius(radius), getBorder(border)
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};
