import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "./Container.scss";

import {
  LoginPage,
  ForgetPasswordPage,
  ResetPasswordPage,
  SetPasswordPage,
  ProfilePage,
  SalesReportPage,
  SalesOverviewPage,
  TeamPage,
  TeamDetailsPage,
  TeamDetailsAddPage,
  Logout,
} from "@pages";
import AuthRoute from "./securedRoute/AuthRoute";
import LoginRoute from "./securedRoute/LoginRoute";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/logout" exact component={Logout} />
        <LoginRoute exact path="/" component={LoginPage} />
        <Route exact path="/forget-password" component={ForgetPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/set-password" component={SetPasswordPage} />
        <AuthRoute exact path="/profile" component={ProfilePage} />
        <AuthRoute exact path="/sales-overview" component={SalesOverviewPage} />
        <AuthRoute exact path="/sales-report" component={SalesReportPage} />
        <AuthRoute exact path="/team" component={TeamPage} />
        <AuthRoute
          exact
          path="/team-details/:id?"
          component={TeamDetailsPage}
        />
        <AuthRoute
          exact
          path="/team-details-add"
          component={TeamDetailsAddPage}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
