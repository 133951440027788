/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { Dispatch } from "@store";

export const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.User.resetState();
    dispatch.UI.resetState();
    dispatch.Table.resetState();
    dispatch.SalesOverview.resetState();
    dispatch.HourlyData.resetState();
    dispatch.Team.resetState();
    dispatch.User.updateState({
      shouldRedirectToLogin: true,
      isUserLoggedIn: false,
    });
    history.push(`/`);
  }, []);

  return <></>;
};
