import { Models } from "@rematch/core";
import { HourlyData } from "./HourlyData";
import { SalesOverview } from "./SalesOverview";
import { Table } from "./TableModel";
import { Team } from "./Team";
import { UI } from "./UIModel"; 
import { User } from "./UserModel"; 

export interface RootModel extends Models<RootModel> {
    UI: typeof UI; 
    Table: typeof Table; 
    User: typeof User;
    SalesOverview: typeof SalesOverview; 
    HourlyData: typeof HourlyData;
    Team: typeof Team;
  }
  
  export const models: RootModel = {
    UI,
    Table,
    User,
    SalesOverview,
    HourlyData,
    Team
  };
  