import { SpinnerCircularFixed } from "spinners-react";

export const Loader = () => { 
    return ( 
        <div className='my-auto items-center overscroll-none bg-white'> 
            <SpinnerCircularFixed 
                className='m-auto h-32'
                style={{color: '#e07720'}}
            />
        </div>  
    );
}   