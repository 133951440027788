import { createModel } from "@rematch/core"; 
import { RootModel } from ".";
 
interface ITableUnsubscribePayload {
  table: string;
  data: any;
}

interface ITableDataPayload {
  table: string;
  data: any; 
  totalRecords?: any; 
}   

let defaultTableValue = { 
  data: [],
  totalRecords: null, 
} 

const initialState = {
  tables: { 
    team: defaultTableValue,
  },
  isLoading: true, 
} as any;

export const Table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */
    resetTable(state, table: string) {
      return {
        ...state,
        tables: { ...state.tables, [table]: { ...initialState.tables[table] } },
      };
    }, 
    setTableData(state, payload: ITableDataPayload) {
      const { table, data, totalRecords = null } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data,
            totalRecords
          },
        },
      };
    }, 
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    },
    setTableUnsubscribe(state, payload: ITableUnsubscribePayload) {
      const { table, data } = payload; 

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            unsubscribe: data
          },
        },
      };
    },
  }, 
});
