/* eslint-disable jsx-a11y/alt-text */ 
import sales from '@assets/icon/sales.png'
import hourly from '@assets/icon/hourly.png'
import team from '@assets/icon/team.png'
import profile from '@assets/icon/profile.png'

// active 
import salesActive from '@assets/icon/sales-active.png'
import hourlyActive from '@assets/icon/hourly-active.png'
import teamActive from '@assets/icon/team-active.png'
import profileActive from '@assets/icon/profile-active.png'

export const MenuData = [
    {
        title: 'Sales Overview', 
        path: '/sales-overview',
        icon: <img src={sales}/>, 
        iconActive: <img src={salesActive}/>, 
    },
    {
        title: 'Hourly Data', 
        path: '/sales-report',
        icon: <img src={hourly}/>, 
        iconActive: <img src={hourlyActive}/>, 
    },
    {
        title: 'Team Members', 
        path: '/team',
        icon: <img src={team}/>, 
        iconActive: <img src={teamActive}/>, 
    },
    {
        title: 'Profile', 
        path: '/profile',
        icon: <img src={profile}/>, 
        iconActive: <img src={profileActive}/>, 
    },
]