import { addTeamMember } from "@api";
import { Container, GridContainer, Button, Span, Inputs } from "@common";
import { InputContainerNew } from "@components";
import { BRAND_IMAGES, ROLE } from "@constant";
import { Dispatch } from "@store";
import { onlyNumberKey, validateEmail } from "@utils";
import { Form, Select, Switch } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../Team.scss";
import { SelectResto } from "./SelectResto";
import { SelectStore } from "./SelectStore";

const { Option } = Select;

export const TeamDetailsAdd = () => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const history = useHistory();

  const initialState = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    contactNumber: "",
    role: "",
    restaurantAccesses: [
      {
        restaurantId: "",
      },
    ],
    storeAccesses: [
      {
        storeId: "",
      },
    ],
  } as any;

  const state = {
    firstNameValid: false,
    middleNameValid: false,
    lastNameValid: false,
    mobileValid: false,
    emailValid: false,
    roleValid: false,
  } as any;

  const [error, setErrorMsg] = useState(state) as any;
  const [teamDetailsAdd, setTeamDetailsAdd] = useState() as any;

  const handleAddTeamMember = async () => {
    try {
      const {
        listChosenRestaurants: restaurantAccessIds,
        listChosenStores: storeAccessIds,
        ...teamDetailsAdd
      } = await form.validateFields();
      const res = await addTeamMember({
        ...teamDetailsAdd,
        contactNumber: `+63${teamDetailsAdd?.contactNumber}`,
        role: teamDetailsAdd?.role,
        accessStatus:
          teamDetailsAdd.accessStatus === true ? "active" : "inactive",
        restaurantAccessIds,
        storeAccessIds,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Team member successfully added!",
          type: "Success",
        });
      }
      setTeamDetailsAdd(initialState);
      history.push("/team");
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable to add team member!",
        type: "Error",
      });
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTeamDetailsAdd({
      ...teamDetailsAdd,
      [name]: value,
    });
  };

  const onBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "firstName":
        const firstNameValid = value.length === 0;
        setErrorMsg({
          ...error,
          firstNameValid: firstNameValid,
        });
        break;
      case "lastName":
        const lastNameValid = value.length === 0;
        setErrorMsg({
          ...error,
          lastNameValid: lastNameValid,
        });
        break;
      case "middleName":
        const middleNameValid = value.length === 0;
        setErrorMsg({
          ...error,
          middleNameValid: middleNameValid,
        });
        break;
      case "email":
        const emailValid = value.length === 0;
        setErrorMsg({
          ...error,
          emailValid: emailValid,
        });
        break;
      case "contactNumber":
        const mobileValid = value.length === 0;
        setErrorMsg({
          ...error,
          mobileValid: mobileValid,
        });
        break;
      default:
    }
  };

  const handleFocus = () => {
    const roleValid = !teamDetailsAdd?.role;
    setErrorMsg({
      ...error,
      roleValid: roleValid,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    history("/team");
  };

  const isEmailValid = validateEmail(teamDetailsAdd?.email);

  return (
    <>
      <GridContainer>
        <div>
          <Form onFinish={handleAddTeamMember} layout="vertical" form={form}>
            <Container padding="p-4" margin="none">
              <Container padding="p-4" margin="none">
                <Container margin="none">
                  <img
                    src={BRAND_IMAGES["DEFAULT_ICON"]}
                    alt="AEG Logo"
                    className="default-icon"
                  />

                  <InputContainerNew
                    icon={false}
                    required={true}
                    label="First Name"
                  >
                    <Form.Item name="firstName" className="w-full">
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="firstName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      {error?.firstNameValid && (
                        <Span color="red">This field is required</Span>
                      )}
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew
                    icon={false}
                    required={true}
                    label="Middle Name"
                  >
                    <Form.Item name="middleName" className="w-full">
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="middleName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      {error?.middleNameValid && (
                        <Span color="red">This field is required</Span>
                      )}
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew
                    icon={false}
                    required={true}
                    label="Last Name"
                  >
                    <Form.Item name="lastName" className="w-full">
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="lastName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      {error?.lastNameValid && (
                        <Span color="red">This field is required</Span>
                      )}
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew
                    icon={false}
                    required={true}
                    label="Mobile"
                  >
                    <div className="flex w-full">
                      <span className="absolute border-b border-grey-2 py-2 text-xs">
                        (+63)
                      </span>
                      <Form.Item name="contactNumber" className="w-full">
                        <Inputs
                          padding="both"
                          border="bottom"
                          name="contactNumber"
                          type="phone"
                          onChange={onChange}
                          maxLength={10}
                          onKeyPress={onlyNumberKey}
                          onBlur={onBlur}
                        />
                        {error?.mobileValid && (
                          <Span color="red">This field is required</Span>
                        )}
                      </Form.Item>
                    </div>
                  </InputContainerNew>

                  <InputContainerNew icon={false} required={true} label="Email">
                    <Form.Item name="email" className="w-full">
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="email"
                        type="email"
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      {error?.emailValid && (
                        <Span color="red">This field is required</Span>
                      )}
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew icon={false} required={true} label="Role">
                    <Form.Item name="role" className="w-full pt-2">
                      <Select
                        className="w-full"
                        placeholder="Select Role"
                        onChange={(v: any) => {
                          setTeamDetailsAdd({ ...teamDetailsAdd, role: v });
                          setErrorMsg({ ...error, roleValid: false });
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onBlur={handleFocus}
                      >
                        {ROLE?.map((type, index) => (
                          <Option key={index} value={type.value}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                      {error?.roleValid && (
                        <Span color="red">This field is required</Span>
                      )}
                    </Form.Item>
                  </InputContainerNew>

                  <SelectResto />
                  <SelectStore />

                  <div className="flex justify-between border border-grey-2 my-2 mx-2 p-4 rounded">
                    <Span color="grey" margin="my-auto">
                      Member Status
                    </Span>
                    <Form.Item name="accessStatus" className="mb-0">
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                      />
                    </Form.Item>
                  </div>
                </Container>
              </Container>
            </Container>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none"
                margin="m-4"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                disabled={
                  !teamDetailsAdd?.firstName ||
                  !teamDetailsAdd?.middleName ||
                  !teamDetailsAdd?.lastName ||
                  !teamDetailsAdd?.contactNumber ||
                  !teamDetailsAdd?.email ||
                  !teamDetailsAdd?.role ||
                  !isEmailValid
                }
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </GridContainer>
    </>
  );
};
