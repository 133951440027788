import {useState, useEffect} from 'react'  
import { Link, useLocation } from 'react-router-dom';     
 
export const Menu = ({item} : { item:any }) => {  
    const [pathname, setPathname] = useState('/');
    const location = useLocation();
    
    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);

    return ( <>
        <li>
            <Link to={item.path} className={pathname?.search(item?.path?.split('?')[0]) > -1 ? 'active-link' : ''}>  
                <div className="flex">
                    <div className="side-menu flex"> 
                        {pathname === item.path ? item.iconActive : item.icon}
                        <span>{item.title}</span>
                    </div>   
                </div> 
            </Link>
        </li>  
    </>);
};