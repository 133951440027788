import { CSVLink } from "react-csv";
import './CSVLink.scss'

type Props = {   
  data: any 
  headers: any
  filename: string
}

export const CSVLinkContent = ({data, headers, filename}: Props) => {

  return <>
    <CSVLink
      data={data}
      headers={headers}
      filename={filename}
      className="w-full csvlink"
    >
      Export Option
    </CSVLink>
  </>;
};
