/* eslint-disable no-useless-escape */

import moment from "moment";

export const validateEmail = (input: string) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (re.test(input)) {
      return true;
    }
    return false;
};

export const formatMonthDay = (date) => {
	const d = new Date(date)
	const formattedDate = moment(d).format('MMM-DD')
	return formattedDate;
}