import { BRAND_IMAGES } from "@constant";
import { CardContainer, Container, Button, Inputs } from "@common";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { validateEmail } from "@utils";
import { useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "@icons";
import { AlertSnackBar, Loader } from "@components";

export const Login = () => {
  const dispatch = useDispatch<Dispatch>();

  const email: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.email
  );
  const password: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.password
  );
  const isRequesting = useSelector(
    (state: RootState) => state.UI.forms.login.isRequesting
  );

  const isUserLoggedIn: any = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );

  const [showPassword, setshowPassword] = useState(false);

  const togglePassword = (e: any) => {
    e.preventDefault();
    setshowPassword(!showPassword);
  };

  const isEmailValid = validateEmail(email);
  const validation = !isEmailValid || !email || !password;

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogin = async () => {
    try {
      dispatch.UI.resetAlert();
      await dispatch.User.login({ email, password });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleEnter = (e: any) => {
    const ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode === 13) {
      handleLogin();
    }
  };

  const setFormValue = (field, value) => {
    dispatch.UI.setInputFormFields({
      target: "login",
      field: field,
      value: value,
    });
  };

  useEffect(() => {
    if (isRequesting) {
      setIsLoading(true);
    }
    if (!isRequesting) {
      setIsLoading(false);
    }
  }, [isRequesting, isUserLoggedIn]);

  return (
    <>
      <AlertSnackBar open={open} setOpen={setOpen} />
      <CardContainer>
        <img
          src={BRAND_IMAGES["BRAND_LOGO"]}
          alt="AEG Logo"
          className="brand-logo"
        />

        {!isLoading ? (
          <>
            <Container className="index" margin="mt-4">
              <Inputs
                required
                autoFocus
                border="bottom"
                placeholder="Email"
                type="email"
                value={email}
                onKeyPress={handleEnter}
                onChange={(e: any) => {
                  setFormValue("email", e.target.value);
                  validateEmail(e);
                }}
              />
              <span className="bar"></span>
            </Container>

            <Container className="index" flex="wrap" margin="mt-4">
              <Inputs
                padding="left"
                border="bottom"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                required
                value={password}
                onKeyPress={handleEnter}
                onChange={(e: any) => setFormValue("password", e.target.value)}
              />
              <span className="bar"></span>
              <span onClick={togglePassword} className="eyes">
                {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
              </span>
            </Container>

            <Button margin="top" onClick={handleLogin} disabled={validation}>
              Login
            </Button>
          </>
        ) : (
          <Loader />
        )}
      </CardContainer>
    </>
  );
};
