/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { getUserCredentials, postAccessValidation } from "@api";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const AuthRoute: React.FC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { shouldRedirectToLogin, isUserLoggedIn } = useSelector(
    (state: RootState) => state.User
  );

  const dispatch = useDispatch<Dispatch>();

  const [accessValidation, setAccessValidation] = useState(false);

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  var accessToken = user?.accessToken;

  const getUserCredential = useCallback(async () => {
    try {
      const userCredentials = await getUserCredentials();

      dispatch.User.updateState({
        userInfo: userCredentials,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    getUserCredential();
  }, []);

  const getAccess = async () => {
    try {
      const res = await postAccessValidation(accessToken);
      if (!res) {
        setAccessValidation(false);
      }
    } catch {
      setAccessValidation(true);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Component {...props} />
        ) : (
          <>
            {shouldRedirectToLogin && accessValidation ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            ) : null}
          </>
        )
      }
    />
  );
};

export default AuthRoute;
