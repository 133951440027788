import BRAND_LOGO from '@assets/AEG logo.png';
import HEADER_LOGO from '@assets/AEG logo white.png';
import DEFAULT_ICON from '@assets/Face.png';
import LINK_EXPIRED from '@assets/registered_invalid_bg.png'

export const BRAND_IMAGES = {
  BRAND_LOGO,
  HEADER_LOGO,
  DEFAULT_ICON
} 

export const LINK_EXPIRED_BG = LINK_EXPIRED 

export const SERVICES = [
  {
    name: "All Services",
    value: "",
  }, 
  {
    name: "Food",
    value: "food",
  },
  {
    name: "Shop",
    value: "mart",
  }, 
];


export const STORE = [
  {
    name: "All Store",
    value: "",
  },
  {
    name: "Coffee Project",
    value: "coffee_project",
  },
  {
    name: "Dear Joe",
    value: "dear_joe",
  }, 
];

export const EXPORT = [
  {
    name: "Export xls",
    value: "xls",
  },
  {
    name: "Export pdf",
    value: "pdf",
  }, 
];

export const DAYS = [
  {
    name: "Last 30 Days",
    value: 30,
  }, 
  {
    name: "Last 15 Days",
    value: 15,
  },
  {
    name: "Last 7 Days",
    value: 7,
  },  
  {
    name: "Custom",
    value: 0,
  },  
  
];

export const CHART = [
  {
    name: "Line Chart",
    value: "line_chart",
  }, 
  {
    name: "Bar Chart",
    value: "bar_chart",
  }, 
];

export const TOP_PRODUCT = [
  {
    name: "Unit Sold",
    value: "unit_sold",
  }, 
];



export const PAYMENT_METHOD = [
  {
    name: "All Method",
    value: "all_method",
  },  
  {
    name: "AllEasy",
    value: "alleasy",
  },  
  {
    name: "Credit Card",
    value: "credit_card",
  },  
  {
    name: "Points",
    value: "points",
  },  
];

export const ORDER_METHOD = [
  {
    name: "All Method",
    value: "all_method",
  },  
  {
    name: "Delivery",
    value: "delivery",
  }, 
  {
    name: "Pick-up",
    value: "pick_up",
  },   
];

export const ROLE = [
  {
    name: "Staff",
    value: "staff",
  }, 
  {
    name: "Admin",
    value: "admin",
  }, 
];

export const ORDER_METHOD_STATUS = {  
  picked_up: "Picked up", 
  delivery: "Delivery",  
}; 

export const PAYMENT_METHOD_STATUS = {  
  all_easy: "All Easy", 
  cash_on_delivery: "Cash",  
  credit_card: "Credit Card",  
  points: "Points",  
}; 