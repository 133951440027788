/* eslint-disable @typescript-eslint/no-unused-vars */
import { BiSortDown, BiSortUp, CgChevronLeft, CgChevronRight } from "@icons";
import { useTable, useSortBy, usePagination } from "react-table";
import { useHistory } from "react-router-dom";
import "./Table.scss";
import { Button, Span } from "@common";

type Props = {
  data: any;
  columns: any;
  header?: boolean;
  footer?: boolean;
};

export const Table = ({ columns, data, header, footer }: Props) => {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    gotoPage,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize }: any = state;

  const handleRowClick = (e: any, row: any) => {
    history.push(`/team-details/${row?.original?.id}`);
  };

  return (
    <>
      <div className="table-content">
        <table {...getTableProps()}>
          {header && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {/* <div className='flex whitespace-nowrap font-normal'> */}
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BiSortDown className="my-auto text-lg mx-2" />
                        ) : (
                          <BiSortUp className="my-auto text-lg mx-2" />
                        )
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={(e) => {
                          handleRowClick(e, row);
                        }}
                        {...cell.getCellProps()}
                        className="cursor-pointer"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>

          {footer && (
            <tfoot>
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => (
                    <td {...column.getFooterProps}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div className="pagination-team">
        <Button
          bg="white"
          color="black"
          margin="none"
          width="none"
          border="grey"
          padding="p-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <CgChevronLeft size={16} />
        </Button>
        <div className="my-auto mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Page</Span>
          {/* <input type='number' defaultValue={pageIndex + 1} className="gotoPage"
                onChange={e => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                    gotoPage(pageNumber)
                }}
                />  */}
          <span className="gotoPage">{pageIndex + 1}</span>
          <Span margin="auto"> of {pageOptions.length}</Span>
        </div>
        <Button
          bg="white"
          margin="none"
          width="none"
          color="black"
          border="grey"
          padding="p-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <CgChevronRight size={16} />
        </Button>

        <div className="mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Rows per page:</Span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="rowsperpage"
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
