/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SubContainer } from "@common";
import { formatMonthDay } from "@utils";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type Props = {
  transaction?: any;
};

export const TransactionsContent = ({ transaction }: Props) => {
  const delivered = transaction?.filter((d: any) => d.status === "delivered");
  const canceled = transaction?.filter((d: any) => d.status === "canceled");

  const deliveredTotal = [delivered?.map((e: any) => e.total)];
  const canceledTotal = [canceled?.map((e: any) => e.total)];

  const set: any = transaction?.map((tra) => formatMonthDay(tra?.date));
  //@ts-ignore
  const trans: any = [...new Set(set)];

  const data = {
    labels: trans,
    datasets: [
      {
        label: "Completed",
        data: deliveredTotal[0],
        backgroundColor: ["#B1CB60"],
        borderColor: ["#fff"],
        borderWidth: 2,
        maxBarThickness: 30, // number (pixels)
        barThickness: 20,
        barPercentage: 0.1,
        categoryPercentage: 0.1,
        minBarLength: 10,
        responsive: true,
      },
      {
        responsive: true,
        label: "Cancelled",
        data: canceledTotal[0],
        backgroundColor: ["#FF7F14"],
        borderColor: ["#fff"],
        borderWidth: 2,
        maxBarThickness: 30, // number (pixels)
        barThickness: 20,
        minBarLength: 10,
        barPercentage: 0.1,
        categoryPercentage: 0.1,
      },
    ],
    legend: {
      position: "left",
    },
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          position: "chartArea",
          boxWidth: 13,
        },
        // position: 'left'
      },
    },
    layout: {
      padding: 5,
    },
    scales: {
      x: {
        grid: {
          display: false,
          innerHeight: 5,
        },
      },
      y: {
        border: {
          display: false,
          innerHeight: 5,
        },
      },
    },
  };

  return (
    <>
      <SubContainer height="none">
        <div className="flex w-full">
          <div className="m-2 w-full h-full pl-1 pr-4 pb-4">
            <Bar data={data} options={options} />
          </div>
        </div>
      </SubContainer>
    </>
  );
};
